<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
    <b-row>
      <b-col sm="3">
        <b-form-group
            class="row"
            label-cols-sm="12"
            :label="$t('warehouse_service.crop_type')"
            label-for="crop_type"
            >
            <b-form-select
              plain
              v-model="search.crop_type_id"
              :options="cropTypeList"
              id="crop_type_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
            class="row"
            label-cols-sm="12"
            :label="$t('warehouse_service.crop_name')"
            label-for="crop_name"
            >
            <b-form-select
              plain
              v-model="search.crop_id"
              :options="cropNameLists"
              id="crop_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          class="row"
          label-cols-sm="12"
          :label="$t('globalTrans.from_date')"
          label-for="from_date"
        >
        <flat-pickr class="form-control"
          v-model="search.from_date"
          placeholder="Select Date"
        ></flat-pickr>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          class="row"
          label-cols-sm="12"
          :label="$t('globalTrans.to_date')"
          label-for="to_date"
        >
        <flat-pickr class="form-control"
          v-model="search.to_date"
          placeholder="Select Date"
        ></flat-pickr>
        </b-form-group>
      </b-col>
      <b-col class="text-right">
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </b-col>
    </b-row>
    </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.farmer_request') }}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <router-link to="/warehouse-farmer/ware-farmer-panel-add-request-form">{{  $t('globalTrans.add_new') }}</router-link> -->
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="listReload">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? $store.state.ExternalUserIrrigation.commonObj.divisionList.filter(document => document.value === data.item.division_id)[0].text_bn : $store.state.ExternalUserIrrigation.commonObj.divisionList.filter(document => document.value === data.item.division_id)[0].text }}
                        </span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? $store.state.ExternalUserIrrigation.warehouse.regionList.filter(document => document.value === data.item.region_id)[0].text_bn : $store.state.ExternalUserIrrigation.warehouse.regionList.filter(document => document.value === data.item.region_id)[0].text }}
                        </span>
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? $store.state.ExternalUserIrrigation.commonObj.districtList.filter(document => document.value === data.item.district_id)[0].text_bn : $store.state.ExternalUserIrrigation.commonObj.districtList.filter(document => document.value === data.item.district_id)[0].text }}
                        </span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_id)="data">
                        <span class="capitalize">
                          {{ $n(0) }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_name)="data">
                        <span class="capitalize">
                          {{ getFarmerData(data.item.farmer_id, 'name') }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_father_name)="data">
                      <span class="capitalize">
                          {{ getFarmerData(data.item.farmer_id, 'father') }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      {{ getFarmerData(data.item.farmer_id, 'land') }}
                    </template>
                    <template v-slot:cell(store_date)="data">
                      <!-- {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.username === data.item.farmer_id)[0].store_date : farmerList.filter(document => document.username === data.item.farmer_id)[0].store_date}} -->
                      {{ data.item.store_date | dateFormat }}
                    </template>
                    <template v-slot:cell(status)="data">
                     <span v-if="data.item.status === 2" class="text-danger">{{ data.item.status ? $t('globalTrans.rejected') : $t('globalTrans.approved') }}</span>
                      <span v-else>{{ data.item.status ? $t('globalTrans.pending') : $t('globalTrans.approved') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-7" size="lg" :title="$t('warehouse_service.farmer_request_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>

import FormV from './Form'
import Detail from './Details'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { warefarmerSelfRequestList, userStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  name: 'UiDataTable',
  components: {
    FormV, Detail
  },
  data () {
    return {
      search: {
        crop_type_id: '0',
        crop_id: '0',
        from_date: '',
        farmer_id: this.$store.state.Auth.authUser.username,
        to_date: ''
      },
      farmer_id: '',
      testId: 0,
      rows: [],
      cropNameLists: [],
      farmerList: []
    }
  },
  computed: {
    authUser: function () {
        return this.$store.state.Auth.authUser
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_service.farmer_request') + ' ' + this.$t('globalTrans.entry') : this.$t('warehouse_service.farmer_request') + ' ' + this.$t('globalTrans.modify')
    },
    cropTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.division'), class: 'text-left' },
          { label: this.$t('globalTrans.region'), class: 'text-left' },
         { label: this.$t('globalTrans.district'), class: 'text-left' },
         { label: this.$t('warehouse_service.warehouse'), class: 'text-left' },
         { label: this.$t('warehouse_service.farmer_id'), class: 'text-left' },
         { label: this.$t('warehouse_service.farmer_name'), class: 'text-left' },
         { label: this.$t('warehouse_service.father_name'), class: 'text-left' },
         { label: this.$t('warehouse_service.landarea'), class: 'text-left' },
         { label: this.$t('globalTrans.date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_id' },
          { key: 'farmer_name' },
          { key: 'farmer_father_name' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_id' },
          { key: 'farmer_name' },
          { key: 'farmer_father_name' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameLists = this.getCropNameList(newVal)
    }
  },
  created () {
    this.checkApprovedUser()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        warehouse_id: '',
        farmer_id: '',
        crop_id: '',
        level_id: '',
        total_quantity: '',
        name: ''
      }
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      await RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
        if (response.success) {
              this.farmerList = response.data
            }
        })
      await RestApi.getData(warehouseServiceBaseUrl, warefarmerSelfRequestList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCropNameList (cropTypeId = null) {
    const cropList = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(item => item.status === 0)
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
          this.loadData()
        } else {
          this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    },
    getFarmerData (farmerId, key = 'name') {
        const farmerdata = this.farmerList.find(item => item.username === farmerId || item.mobile_no === farmerId)
        if (farmerdata) {
            if (key === 'name') {
                return this.$i18n.locale === 'en' ? farmerdata.name : farmerdata.name_bn
            }
            if (key === 'father') {
                return this.$i18n.locale === 'en' ? farmerdata.father_name : farmerdata.father_name_bn
            }
            if (key === 'land') {
                return this.$n(farmerdata.land_area)
            }
        } else {
            return null
        }
    }
  }
}
</script>
